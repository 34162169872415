.logoCause {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
}
.buttonRight {
    float: right;
}

.centerDiv {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.buttonWidth {
    width: 100%;
}