.centerDiv {

}
.searchBox{
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.loader-box{
    margin: 900px;
}