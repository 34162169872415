@import url(https://fonts.googleapis.com/css?family=Raleway:400,200);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(http://fonts.googleapis.com/css?family=Lato:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.register_hrCustom__33gDI {
    border: none;
    border-top: 1px solid #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
}

.register_hrCustom__33gDI:after {
    background: #fff;
    content: 'or';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.register_form__YxaZQ {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.register_logoButton__RA6Ii {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.register_centerDiv__21qr2 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.register_logoCause__YKfJD {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}
.register_buttonWidth__Ee4Fg {
    width: 80%;
}

/*
...................................................................
 */
/* @extend display-flex; */
.register_fa__3TWHv {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}

/* Add a hover effect if you want */
.register_fa__3TWHv:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.register_fa-facebook__1FiNS {
    background: #3B5998;
    color: white;
}

/* Twitter */
.register_fa-twitter__3snhF {
    background: #55ACEE;
    color: white;
}
.SocialButton_centerDiv__a6uQo {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.SmallWindowBox_rcorners3__3Jr4r {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px 50px;
   /* border-color: #282c34;
    border-style: inset;*/
    padding: 20px;
    width: content-box;
    height: content-box;
}

.SmallWindowBox_centerDiv__35U4D {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

}

.SmallWindowBox_buttonWidth__1eDHT {
    width: 300px;
}
.DefaultRegister_logoCause__o0Cpn {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
}
.DefaultRegister_buttonRight__I5G-T {
    float: right;
}

.DefaultRegister_centerDiv__170h_ {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.DefaultRegister_buttonWidth__3vZhF {
    width: 100%;
}
.topbar_avatar__IRBFF {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.topbar_paddingLogo__1_98S {
    margin-right: 3px;
}

.topbar_btnRegister__2ikkI {
    margin-right: 30px;
    margin-left: 20px;

}

.topbar_logoM__17dBo {

    margin-left: 20px;

}

html {
    background: #ffffff;
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;
    font-family: 'Raleway', sans-serif;
}

.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf {
    background: #fff;
    border-radius: 0;
    border-bottom: 1px solid #bfbfbf;
    color: #8f8f8f;
}

.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf .topbar_navbar-brand__3sTa6 {
    font-weight: 200;
}

.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf a {
    color: #8f8f8f;
}

.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf ul > li > a:hover,
.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf li.topbar_open__2MiJ2 a {
    background: #fafafa;
}

.topbar_navbar__xoaMA.topbar_navbar-clean__NuNmf a i {
    position: relative;
    top: 1px;
    margin-left: 6px;
}

/* Required for Dropdowns */
.topbar_dropdown__cicgD {
    position: relative;
}
.topbar_dropdown-toggle__2mXk8:focus {
    outline: 0;
}
.topbar_dropdown-menu__3wagv {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 200px;
    padding: 5px 0;
    margin: 15px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fafafa;
    border: 1px solid #bfbfbf;
    border-radius: 2px;
    box-shadow: none;
}
.topbar_dropdown-menu__3wagv:before {
    content: "\00a0";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: -6px;
    border: 1px solid #8f8f8f;
    border-right: 0;
    border-bottom: 0;
    background-color: #fafafa;
    left: 10px;
    transform: rotate(48deg) skew(5deg);
}

li .topbar_dropdown-menu__3wagv:before {
    height: 0;
    width: 0;
}
.topbar_dropdown-menu__3wagv.topbar_pull-right__1EGlo {
    right: 0;
    left: auto;
}
.topbar_dropdown-menu__3wagv .topbar_divider__NSxZw {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #bfbfbf;
}
.topbar_dropdown-menu__3wagv > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    text-decoration: none;
    line-height: 2;
    color: #8f8f8f;
    white-space: nowrap;
}
.topbar_dropdown-menu__3wagv > li > a:hover,
.topbar_dropdown-menu__3wagv > li > a:focus {
    text-decoration: none;
    color: #808080;
    background-color: #f3f3f3;
}
.topbar_open__2MiJ2 > .topbar_dropdown-menu__3wagv {
    display: block;
}
.topbar_open__2MiJ2 > a {
    outline: 0;
}
.topbar_dropdown-menu-right__1Oilq {
    left: auto;
    right: 0;
}
.topbar_dropdown-menu-left__lXZV1 {
    left: 0;
    right: auto;
}
.topbar_dropdown-header__1JJZ6 {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap;
}
.topbar_dropdown-backdrop__GG1Bg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}
.topbar_pull-right__1EGlo > .topbar_dropdown-menu__3wagv {
    right: 0;
    left: auto;
}
.topbar_dropup__1DOJL .topbar_caret__sbDzl,
.topbar_navbar-fixed-bottom__2ScJK .topbar_dropdown__cicgD .topbar_caret__sbDzl {
    border-top: 0;
    border-bottom: 4px solid;
    content: "";
}
.topbar_dropup__1DOJL .topbar_dropdown-menu__3wagv,
.topbar_navbar-fixed-bottom__2ScJK .topbar_dropdown__cicgD .topbar_dropdown-menu__3wagv {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}
@media (min-width: 768px) {
    .topbar_navbar-right__3rjAp .topbar_dropdown-menu__3wagv {
        left: auto;
        right: 0;
    }
    .topbar_navbar-right__3rjAp .topbar_dropdown-menu-left__lXZV1 {
        left: 0;
        right: auto;
    }
}
.topbar_button-group__1Wu_m,
.topbar_button-group-vertical__2Gzea {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.topbar_button-group__1Wu_m > .topbar_button__1vUvY,
.topbar_button-group-vertical__2Gzea > .topbar_button__1vUvY {
    position: relative;
    float: left;
}

ul.UserData_list-group__3ZBv0:after {
    clear: both;
    display: block;
    content: "";
}

.UserData_list-group-item__3pfWh {
    flex-direction: row;
    float: left;
}
.UserData_avatar__rEbGl {
    vertical-align: middle;
    width: 40px;
    height: 40px;
}
.UserData_contsiner__1sxJu{
    padding-left: 5px;
}
.notfound_bodyCLass__1OIBS {
    margin: 0;
    background: #263238;
}

.notfound_line__3nOom {
    position: fixed;
    width: 550px;
    height: 2px;
    background: #90A4AE;
    left: calc(50vw - 275px);
    top: calc(50vh + 73px);
}

.notfound_bowl__1WhzB {
    background: #CFD8DC;
    position: fixed;
    width: 100px;
    height: 45px;
    border-radius: 5px 5px 50px 50px;
    left: calc(50vw - 25px);
    top: calc(50vh + 19px);
    border: 4px solid #161c1f;
    box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.notfound_signwood__3_Bel {
    background: #5D4037;
    position: fixed;
    width: 17px;
    height: 150px;
    border-radius: 5px;
    left: calc(50vw - 80px);
    top: calc(50vh - 83px);
    border-top: 4px solid #3E2723;
    border-left: 4px solid #3E2723;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #3E2723;
    box-shadow: inset -4px 0 0 rgba(0, 0, 0, 0.2);
}

.notfound_signtext__30cS8 {
    background: #8a6b47;
    position: fixed;
    width: 125px;
    height: 60px;
    border-radius: 5px;
    left: calc(50vw - 147px);
    top: calc(50vh - 87px);
    transform: rotate(-15deg);
    transform-origin: 60% 0%;
    border: 4px solid #58442d;
    transition: transform 0.3s ease;
}

.notfound_signtext__30cS8:hover {
    transform: rotate(-20deg);
}

.notfound_signtext__30cS8 > p {
    text-align: center;
    margin: 10px 0px 0px 0px;
    padding: 0;
    font-weight: bold;
    font-size: 13px;
    font-family: Roboto Slab;
    color: rgba(0, 0, 0, 0.8);
}

.notfound_pin__3vVug {
    width: 5px;
    height: 5px;
    position: fixed;
    background: #9E9E9E;
    border: 1px solid #424242;
    border-radius: 100%;
    left: calc(50vw - 70px);
    top: calc(50vh - 79px);
}

.notfound_spider__Ilj79 {
    width: 10px;
    height: 10px;
    background: #161c1f;
    position: absolute;
    border-radius: 100%;
    left: 25px;
    top: 15px;
    transition: transform 0.3s ease;
}

.notfound_leg__20odR {
    width: 5px;
    height: 1px;
    position: absolute;
    background: #161c1f;
}

.notfound_left-1__P3RVo {
    margin-left: -4px;
    transform: rotate(15deg);
    margin-top: 1px;
}

.notfound_left-2__QomJR {
    margin-left: -4px;
    transform: rotate(0deg);
    margin-top: 2px;
}

.notfound_left-3__18vPa {
    margin-left: -4px;
    transform: rotate(-15deg);
    margin-top: 2px;
}

.notfound_right-1__1hkm5 {
    margin-left: 8px;
    transform: rotate(-15deg);
    margin-top: -6px;
}

.notfound_right-2__2WH8W {
    margin-left: 8px;
    transform: rotate(0deg);
    margin-top: 2px;
}

.notfound_right-3__3mjgu {
    margin-left: 8px;
    transform: rotate(15deg);
    margin-top: 2px;
}

.notfound_spdline__1nAru {
    position: absolute;
    background: #161c1f;
    width: 1px;
    height: 20px;
    left: 30px;
}

.notfound_bowl__1WhzB:hover > .notfound_spider__Ilj79 {
    transform: translate(0, 5px);
}

.notfound_bowl__1WhzB:hover + .notfound_ghost__3WVz0 {
    transform: translate(0, -15px);
    opacity: 1;
}

.notfound_ghost__3WVz0:hover {
    transform: translate(0, -15px);
    opacity: 1;
}

.notfound_ghost__3WVz0 {
    position: fixed;
    height: 65px;
    width: 50px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100px 100px 5px 5px;
    left: 15px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    left: calc(50vw + 17px);
    top: calc(50vh - 12px);
    opacity: 0.25;
    z-index: -50;
    box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.07);
    transition: opacity 0.5s ease, transform 0.3s ease;
}

.notfound_eye__1JUwb {
    position: absolute;
    width: 7px;
    height: 10px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.notfound_eye-left__3lpaj {
    left: 14px;
    top: 15pX;
}

.notfound_eye-right__29ZS0 {
    left: 28px;
    top: 15pX;
}

.notfound_mouth__2IUDj {
    position: absolute;
    width: 20px;
    height: 13px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0);
    top: 21px;
    left: 14px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    border-bottom: 2px solid #c9ccce;
}

.notfound_chocolate__1RFOB {
    position: absolute;
    width: 4px;
    height: 7px;
    border-radius: 0px 0px 100% 100%;
    background: #5D4037;
    right: 15px;
    top: 35px;
}
.internalerror_bodyclass__1a_wE {
    margin: 0;
    background: #263238;
}

.internalerror_line__2c6Zg {
    position: fixed;
    width: 550px;
    height: 2px;
    background: #90A4AE;
    left: calc(50vw - 275px);
    top: calc(50vh + 93px);
}

.internalerror_bowl__3T2oL {
    background: #CFD8DC;
    position: fixed;
    width: 100px;
    height: 45px;
    border-radius: 5px 5px 50px 50px;
    left: calc(50vw - 25px);
    top: calc(50vh + 19px);
    border: 4px solid #161c1f;
    box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.internalerror_signwood__11qej {
    background: #5D4037;
    position: fixed;
    width: 17px;
    height: 180px;
    border-radius: 5px;
    left: calc(50vw - 80px);
    top: calc(50vh - 83px);
    border-top: 4px solid #3E2723;
    border-left: 4px solid #3E2723;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #3E2723;
    box-shadow: inset -4px 0 0 rgba(0, 0, 0, 0.2);
}

.internalerror_signtext__D4RFi {
    background: #8a6b47;
    position: fixed;
    width: 125px;
    height: 80px;
    border-radius: 5px;
    left: calc(50vw - 147px);
    top: calc(50vh - 87px);
    transform: rotate(-15deg);
    transform-origin: 60% 0%;
    border: 4px solid #58442d;
    transition: transform 0.3s ease;
}

.internalerror_signtext__D4RFi:hover {
    transform: rotate(-20deg);
}

.internalerror_signtext__D4RFi > p {
    text-align: center;
    margin: 10px 0px 0px 0px;
    padding: 0;
    font-weight: bold;
    font-size: 13px;
    font-family: Roboto Slab;
    color: rgba(0, 0, 0, 0.8);
}

.internalerror_pin__34qM_ {
    width: 5px;
    height: 5px;
    position: fixed;
    background: #9E9E9E;
    border: 1px solid #424242;
    border-radius: 100%;
    left: calc(50vw - 70px);
    top: calc(50vh - 79px);
}

.internalerror_spider__37VKn {
    width: 10px;
    height: 10px;
    background: #161c1f;
    position: absolute;
    border-radius: 100%;
    left: 25px;
    top: 15px;
    transition: transform 0.3s ease;
}

.internalerror_leg__2Fp6W {
    width: 5px;
    height: 1px;
    position: absolute;
    background: #161c1f;
}

.internalerror_left-1__1ITOp {
    margin-left: -4px;
    transform: rotate(15deg);
    margin-top: 1px;
}

.internalerror_left-2__3scYq {
    margin-left: -4px;
    transform: rotate(0deg);
    margin-top: 2px;
}

.internalerror_left-3__25uWa {
    margin-left: -4px;
    transform: rotate(-15deg);
    margin-top: 2px;
}

.internalerror_right-1__2cM8s {
    margin-left: 8px;
    transform: rotate(-15deg);
    margin-top: -6px;
}

.internalerror_right-2__1qdVM {
    margin-left: 8px;
    transform: rotate(0deg);
    margin-top: 2px;
}

.internalerror_right-3__2EwF8 {
    margin-left: 8px;
    transform: rotate(15deg);
    margin-top: 2px;
}

.internalerror_spdline__3rWwM {
    position: absolute;
    background: #161c1f;
    width: 1px;
    height: 20px;
    left: 30px;
}

.internalerror_bowl__3T2oL:hover > .internalerror_spider__37VKn {
    transform: translate(0, 5px);
}

.internalerror_bowl__3T2oL:hover + .internalerror_ghost__X0NL- {
    transform: translate(0, -15px);
    opacity: 1;
}

.internalerror_ghost__X0NL-:hover {
    transform: translate(0, -15px);
    opacity: 1;
}

.internalerror_ghost__X0NL- {
    position: fixed;
    height: 65px;
    width: 50px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100px 100px 5px 5px;
    left: 15px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    left: calc(50vw + 17px);
    top: calc(50vh - 12px);
    opacity: 0.25;
    z-index: -50;
    box-shadow: inset -5px 0 0 rgba(0, 0, 0, 0.07);
    transition: opacity 0.5s ease, transform 0.3s ease;
}

.internalerror_eye__13lg- {
    position: absolute;
    width: 7px;
    height: 10px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.internalerror_eye-left__QuJ5U {
    left: 14px;
    top: 15pX;
}

.internalerror_eye-right__3dEDl {
    left: 28px;
    top: 15pX;
}

.internalerror_mouth__UzX2L {
    position: absolute;
    width: 20px;
    height: 13px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0);
    top: 21px;
    left: 14px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    border-bottom: 2px solid #c9ccce;
}

.internalerror_chocolate__ctQBq {
    position: absolute;
    width: 4px;
    height: 7px;
    border-radius: 0px 0px 100% 100%;
    background: #5D4037;
    right: 15px;
    top: 35px;
}
.Success_rcorners3__1vhtE {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px 50px;
    border-color: #282c34;
    border-style: inset;
    padding: 20px;
    width: 400px;
    height: 150px;
}

.Success_centerDiv__y0FNH {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;

}
.SocialSharing_hrCustom__WIauR {
    border: none;
    border-top: 1px solid #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
}

.SocialSharing_hrCustom__WIauR:after {
    background: #fff;
    content: 'or';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.SocialSharing_form__2TGKk {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.SocialSharing_logoButton__2D1Ly {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.SocialSharing_centerDiv__2sOsh {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.SocialSharing_logoCause__3Ep4- {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
}
.SocialSharing_buttonWidth__1ssYQ {
    width: 80%;
}

/*
...................................................................
 */
/* @extend display-flex; */
.SocialSharing_fa__3funv {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}

/* Add a hover effect if you want */
.SocialSharing_fa__3funv:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.SocialSharing_fa-facebook__3HS1V {
    background: #3B5998;
    color: white;
}

/* Twitter */
.SocialSharing_fa-twitter__2bXnw {
    background: #55ACEE;
    color: white;
}
body
{
    font-family: 'Lato', 'sans-serif';
}
.profile_img-circle__2_9tH {
    border-radius: 50%;
}
.profile_causeLogo__3wcyI{
    vertical-align: middle;
    width: 50px;
    height: 50px;
}
.profile_profile__ZqxvQ
{
    min-height: 355px;
    display: inline-block;
}
figcaption.profile_ratings__jL0Nj
{
    margin-top:20px;
}
figcaption.profile_ratings__jL0Nj a
{
    color:#f1c40f;
    font-size:11px;
}
figcaption.profile_ratings__jL0Nj a:hover
{
    color:#f39c12;
    text-decoration:none;
}
.profile_divider__3uW6A
{
    border-top:1px solid rgba(0,0,0,0.1);
}
.profile_emphasis__2TWDl
{
    border-top: 4px solid transparent;
}
.profile_emphasis__2TWDl:hover
{
    border-top: 4px solid #1abc9c;
}
.profile_emphasis__2TWDl h2
{
    margin-bottom:0;
}
span.profile_tags__2av2j
{
    background: #1abc9c;
    border-radius: 2px;
    color: #f5f5f5;
    font-weight: bold;
    padding: 2px 4px;
}
.profile_dropdown-menu__1Vh48
{
    background-color: #34495e;
    box-shadow: none;
    -webkit-box-shadow: none;
    width: 250px;
    margin-left: -125px;
    left: 50%;
}
.profile_dropdown-menu__1Vh48 .profile_divider__3uW6A
{
    background:none;
}
.profile_dropdown-menu__1Vh48>li>a
{
    color:#f5f5f5;
}
.profile_dropup__uVWhy .profile_dropdown-menu__1Vh48
{
    margin-bottom:10px;
}
.profile_dropup__uVWhy .profile_dropdown-menu__1Vh48:before
{
    content: "";
    border-top: 10px solid #34495e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    z-index: 10;
}
.profile_well__3r5dp {
    min-height:20px;
    padding:19px;
    margin-bottom:20px;
    background-color:#f5f5f5;
    border:1px solid #e3e3e3;
    border-radius:4px;
    box-shadow:inset 0 1px 1px rgba(0,0,0,.05)
}
.profile_well__3r5dp blockquote {
    border-color:#ddd;
    border-color:rgba(0,0,0,.15)
}
.profile_well-lg__3BLym {
    padding:24px;
    border-radius:6px
}
.profile_well-sm__1tE5J {
    padding:9px;
    border-radius:3px
}
.profile_center__3QRsS{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}
.email_center__2fTpa{
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 70%;
}
.email_centerDiv__1eggX {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.cards_box__3Epn2{
    margin-bottom: 10px;
}
.cards_mobile-cards__2tOwz{
    font-size: small;
}
.featured-selected_centerDiv__3HDGN {
    margin-top:30px ;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.featured-selected_logoCause__1GVeY {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}
.search_centerDiv__2tmk4 {

}
.search_searchBox__h30sG{
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.search_loader-box__eMkrQ{
    margin: 900px;
}
