@import url(http://fonts.googleapis.com/css?family=Lato:400,700);
body
{
    font-family: 'Lato', 'sans-serif';
}
.img-circle {
    border-radius: 50%;
}
.causeLogo{
    vertical-align: middle;
    width: 50px;
    height: 50px;
}
.profile
{
    min-height: 355px;
    display: inline-block;
}
figcaption.ratings
{
    margin-top:20px;
}
figcaption.ratings a
{
    color:#f1c40f;
    font-size:11px;
}
figcaption.ratings a:hover
{
    color:#f39c12;
    text-decoration:none;
}
.divider
{
    border-top:1px solid rgba(0,0,0,0.1);
}
.emphasis
{
    border-top: 4px solid transparent;
}
.emphasis:hover
{
    border-top: 4px solid #1abc9c;
}
.emphasis h2
{
    margin-bottom:0;
}
span.tags
{
    background: #1abc9c;
    border-radius: 2px;
    color: #f5f5f5;
    font-weight: bold;
    padding: 2px 4px;
}
.dropdown-menu
{
    background-color: #34495e;
    box-shadow: none;
    -webkit-box-shadow: none;
    width: 250px;
    margin-left: -125px;
    left: 50%;
}
.dropdown-menu .divider
{
    background:none;
}
.dropdown-menu>li>a
{
    color:#f5f5f5;
}
.dropup .dropdown-menu
{
    margin-bottom:10px;
}
.dropup .dropdown-menu:before
{
    content: "";
    border-top: 10px solid #34495e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    z-index: 10;
}
.well {
    min-height:20px;
    padding:19px;
    margin-bottom:20px;
    background-color:#f5f5f5;
    border:1px solid #e3e3e3;
    border-radius:4px;
    -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow:inset 0 1px 1px rgba(0,0,0,.05)
}
.well blockquote {
    border-color:#ddd;
    border-color:rgba(0,0,0,.15)
}
.well-lg {
    padding:24px;
    border-radius:6px
}
.well-sm {
    padding:9px;
    border-radius:3px
}
.center{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}