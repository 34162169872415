.hrCustom {
    border: none;
    border-top: 1px solid #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
}

.hrCustom:after {
    background: #fff;
    content: 'or';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.form {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.logoButton {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.centerDiv {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.logoCause {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
}
.buttonWidth {
    width: 80%;
}

/*
...................................................................
 */
/* @extend display-flex; */
.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
}

/* Add a hover effect if you want */
.fa:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
    background: #3B5998;
    color: white;
}

/* Twitter */
.fa-twitter {
    background: #55ACEE;
    color: white;
}