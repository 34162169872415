.center{
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 70%;
}
.centerDiv {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
