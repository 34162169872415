.centerDiv {
    margin-top:30px ;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.logoCause {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}