ul.list-group:after {
    clear: both;
    display: block;
    content: "";
}

.list-group-item {
    flex-direction: row;
    float: left;
}
.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
}
.contsiner{
    padding-left: 5px;
}